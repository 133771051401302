import React, { useEffect, useRef } from "react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import kebabCase from "lodash.kebabcase"
import { graphql, Link } from "gatsby"
import Layout from "../components/Layout/Layout"
import Seo from "../components/Seo/Seo"
import styled from "styled-components"
import { FaArrowLeft, FaArrowRight, FaPrint } from "react-icons/fa"
import Image from "../components/Images/Images"
import useDarkMode from "use-dark-mode"
import Toc from "../components/Toc/Toc"
import Comment from "../components/Comment/Comment"
import Heading from "../components/Headings/Heading"
import { useReactToPrint } from "react-to-print"

const Wrap = styled.div`
  max-height: 77vh;
  display: flex;
`

const InnerScroll = styled.div`
  overflow: hidden;
  overflow-y: scroll;
  width: 100%;
`
const Pagination = styled.div`
  height: auto;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 1fr 1fr;

  > a {
    text-decoration: none;
    border-radius: 4px;
    background: ${props => (props.dark ? "#2c2c2c" : "#eaeaea")};
    &:nth-child(2) {
      text-align: right;
    }
    span {
      color: #4e4e55;
      font-size: 16px;
      text-transform: uppercase;
    }
    h3 {
      font-size: 22px;
      font-weight: 700;
      line-height: 27px;
      margin-top: 20px;
    }
  }
  > a:hover {
    background: ${props => (props.dark ? "#3c3c3c" : "#fafafa")};
    border: 1px solid red !important;
    box-shadow: 0 15px 25px 0 rgba(0, 0, 0, 0.3) !important;
  }
`

const commentBox = React.createRef()

const BlogPost = ({ data, pageContext }) => {
  const { mdx } = data
  const { prev, next } = pageContext
  const darkMode = useDarkMode(false)

  useEffect(() => {
    const commentScript = document.createElement("script")
    commentScript.async = true
    commentScript.src = "https://utteranc.es/client.js"
    commentScript.setAttribute("repo", "abdullahbq/inkredibledoc-comments")
    commentScript.setAttribute("issue-term", "title")
    commentScript.setAttribute("label", "comment")
    commentScript.setAttribute("id", "utterances")

    darkMode.value === true
      ? commentScript.setAttribute("theme", "photon-dark")
      : commentScript.setAttribute("theme", "github-light")

    commentScript.setAttribute("crossorigin", "anonymous")
    if (commentBox && commentBox.current) {
      commentBox.current.appendChild(commentScript)
    } else {
      console.log(`Error with utterances comments on: ${commentBox}`)
    }

    const removeScript = () => {
      commentScript.remove()
      document.querySelectorAll(".utterances").forEach(el => el.remove())
    }

    return () => {
      removeScript()
    }
  }, [darkMode])

  const componentRef = useRef()
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  })

  return (
    <Layout>
      <Seo title={mdx.frontmatter.title} />
      <section className="blog-post-section">
        <div
          className="card shadow"
          style={{
            borderRadius: "0px",
            backgroundColor: "#" + Math.random().toString(16).slice(-6) + "10",
          }}
        >
          <div className="card-body">
            <div className="figure shadow">
              {mdx.frontmatter.image ? (
                <Image
                  filename={`${mdx.frontmatter.image}.jpg`}
                  style={{ borderRadius: "4px", height: "466px" }}
                  alt={mdx.frontmatter.image}
                />
              ) : (
                <div
                  className="text-center text-light py-5"
                  style={{
                    borderRadius: "4px",
                    backgroundColor: "#777",
                    height: "300px",
                  }}
                >
                  No Image
                </div>
              )}
              <div className="figcaption-left">
                <div className="row justify-content-center align-items-center mb-4">
                  <div className="col-lg-9 mb-4">
                    <Link
                      to={`/category/${kebabCase(mdx.frontmatter.category)}`}
                      className="badge bg-primary text-light text-decoration-none shadow"
                      style={{ fontSize: "0.9em" }}
                    >
                      {mdx.frontmatter.category}
                    </Link>
                  </div>
                  <div className="col-lg-3">
                    <Pagination dark={darkMode.value}>
                      {prev ? (
                        <Link
                          to={`/blog${prev.node.fields.slug}`}
                          className="btn text-center border-primary shadow"
                          style={{
                            backgroundColor:
                              "#" + Math.random().toString(16).slice(-6) + "10",
                          }}
                          data-toggle="tooltip"
                          data-placement="top"
                          title={prev.node.frontmatter.title}
                        >
                          <span className="text-primary">
                            <FaArrowLeft /> Previous
                          </span>
                        </Link>
                      ) : (
                        <div></div>
                      )}
                      {next ? (
                        <Link
                          to={`/blog${next.node.fields.slug}`}
                          className="btn text-center border-primary shadow"
                          style={{
                            backgroundColor:
                              "#" + Math.random().toString(16).slice(-6) + "10",
                          }}
                          data-toggle="tooltip"
                          data-placement="top"
                          title={next.node.frontmatter.title}
                        >
                          <span className="text-primary">
                            Next <FaArrowRight />
                          </span>
                        </Link>
                      ) : (
                        <div></div>
                      )}
                    </Pagination>
                  </div>
                </div>
                <h1 className="text-light fat-text">{mdx.frontmatter.title}</h1>
              </div>
            </div>
          </div>

          {/* <div className="card-body">
            {mdx.frontmatter.image ? (
              <Image
                className="card-img-top shadow mb-4"
                filename={`${mdx.frontmatter.image}.jpg`}
                alt={mdx.frontmatter.image}
                style={{ borderRadius: "4px", height: "320px" }}
              />
            ) : (
              <div style={{ height: "343px" }}></div>
            )}
            <div className="row justify-content-center align-items-center">
              <div className="col-lg-9">
                <p className="text-muted">
                  <Link
                    to={`/category/${kebabCase(mdx.frontmatter.category)}`}
                    className="badge bg-primary text-light text-decoration-none shadow"
                    style={{ fontSize: "0.9em" }}
                  >
                    {mdx.frontmatter.category}
                  </Link>
                </p>{" "}
                <h1 className="mb-4" style={FAT_TEXT}>
                  {mdx.frontmatter.title}
                </h1>
              </div>
              <div className="col-lg-3">
                <Pagination dark={darkMode.value}>
                  {prev ? (
                    <Link
                      to={`/blog${prev.node.fields.slug}`}
                      className="btn text-center border-primary shadow"
                      style={{
                        backgroundColor:
                          "#" + Math.random().toString(16).slice(-6) + "10",
                      }}
                      data-toggle="tooltip"
                      data-placement="top"
                      title={prev.node.frontmatter.title}
                    >
                      <span className="text-primary">
                        <FaArrowLeft /> Previous
                      </span>
                    </Link>
                  ) : (
                    <div></div>
                  )}
                  {next ? (
                    <Link
                      to={`/blog${next.node.fields.slug}`}
                      className="btn text-center border-primary shadow"
                      style={{
                        backgroundColor:
                          "#" + Math.random().toString(16).slice(-6) + "10",
                      }}
                      data-toggle="tooltip"
                      data-placement="top"
                      title={next.node.frontmatter.title}
                    >
                      <span className="text-primary">
                        Next <FaArrowRight />
                      </span>
                    </Link>
                  ) : (
                    <div></div>
                  )}
                </Pagination>
              </div>
            </div>
          </div> */}
          <div
            className="card-header d-flex justify-content-between align-items-center"
            style={{
              borderRadius: "0px",
              backgroundColor:
                "#" + Math.random().toString(16).slice(-6) + "50",
            }}
          >
            <span className="d-flex text-muted justify-content-between align-items-center">
              <Link to={`/author/${kebabCase(mdx.frontmatter.author)}`}>
                <Image
                  className="card-img-top shadow mx-1"
                  filename={`${mdx.frontmatter.author}.jpg`}
                  alt={mdx.frontmatter.author}
                  style={{
                    borderRadius: "50%",
                    height: "40px",
                    width: "40px",
                    border: "1px solid #7a5bad",
                  }}
                />
              </Link>
              {mdx.frontmatter.author}
            </span>
            <span className="text-muted">{mdx.frontmatter.date}</span>
          </div>
        </div>
        <div className="container-fluid py-5">
          <div className="row justify-content-center">
            <div
              className="col-lg-3 col-md-12 col-sm-12 mb-4 order-md-2"
              id="sticky-sidebar"
            >
              {typeof mdx.tableOfContents.items === "undefined" ? null : (
                <div
                  className="card shadow border-primary sticky-top"
                  style={{
                    backgroundColor:
                      "#" + Math.random().toString(16).slice(-6) + "10",
                  }}
                >
                  <div className="card-header text-center">
                    <h5 className="fat-text">Table of Content</h5>
                  </div>
                  <Wrap>
                    <InnerScroll className="card-body small no-scrollbar">
                      <Toc items={mdx.tableOfContents.items} />
                    </InnerScroll>
                  </Wrap>
                </div>
              )}
            </div>
            <div className="col-lg-9 col-md-12 col-sm-12 mb-4">
              <div
                className="card shadow border-primary mb-4"
                style={{
                  backgroundColor:
                    "#" + Math.random().toString(16).slice(-6) + "10",
                }}
              >
                <h5 className="card-header text-center fat-text">
                  <button
                    className="btn btn-outline-primary btn-sm mx-2"
                    onClick={handlePrint}
                  >
                    <FaPrint /> Print this Article
                  </button>
                </h5>
                <div className="card-body" ref={componentRef}>
                  <MDXRenderer>{mdx.body}</MDXRenderer>
                </div>
                <div className="card-footer">
                  <h2 className="text-center">
                    <Heading>Comments...</Heading>
                  </h2>
                  <Comment commentBox={commentBox} />
                </div>
              </div>
              <Pagination dark={darkMode.value}>
                {prev ? (
                  <Link
                    to={`/blog${prev.node.fields.slug}`}
                    className="btn text-center border-primary shadow"
                    style={{
                      backgroundColor:
                        "#" + Math.random().toString(16).slice(-6) + "10",
                    }}
                  >
                    <span className="text-primary">
                      <FaArrowLeft /> Previous
                    </span>
                    <h3 className="text-secondary">
                      {prev.node.frontmatter.title}
                    </h3>
                  </Link>
                ) : (
                  <div></div>
                )}
                {next ? (
                  <Link
                    to={`/blog${next.node.fields.slug}`}
                    className="btn text-center border-primary shadow"
                    style={{
                      backgroundColor:
                        "#" + Math.random().toString(16).slice(-6) + "10",
                    }}
                  >
                    <span className="text-primary">
                      Next <FaArrowRight />
                    </span>
                    <h3 className="text-secondary">
                      {next.node.frontmatter.title}
                    </h3>
                  </Link>
                ) : (
                  <div></div>
                )}
              </Pagination>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default BlogPost

export const query = graphql`
  query BlogPostBySlug($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      body
      timeToRead
      id
      fields {
        slug
      }
      tableOfContents
      excerpt
      frontmatter {
        title
        category
        author
        date(formatString: "DD MMMM, YYYY")
        image
      }
    }
  }
`
